import React, { ReactNode, useRef, useState } from "react";
import { Control, Controller, ControllerProps } from "react-hook-form";
import {
  Box,
  FormControl,
  FormLabel,
  Typography,
  useTheme,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { StyledInput } from "@/components/styled";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

type FormDatePickerProps = {
  name: string;
  control: Control<any, any>;
  label?: ReactNode;
  controlProps?: Partial<ControllerProps>;
  disablePast?: boolean;
};
const FormDatePicker = ({
  name,
  control,
  label,
  disablePast = false,
  controlProps,
}: FormDatePickerProps) => {
  const isRequired = !!controlProps?.rules?.required;
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const inputRef = useRef<HTMLElement | null>(null);
  return (
    <FormControl component="fieldset" sx={{ width: "100%" }}>
      <FormLabel component="legend" sx={{ width: "100%", display: "flex" }}>
        <Box
          sx={{
            fontSize: "12px",
            fontWeight: 500,
            color: "#373533",
            paddingBottom: "2px",
            display: "flex",
          }}
        >
          {label}{" "}
          {isRequired && (
            <Typography sx={{ color: "red", paddingLeft: "4px" }}>*</Typography>
          )}
        </Box>
      </FormLabel>
      <Controller
        name={name}
        control={control}
        {...controlProps}
        render={({ field: { onChange, value } }) => {
          return (
            <Box sx={{ position: "relative", width: "100%" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ width: "100%" }}
                  open={openDatePicker}
                  onClose={() => setOpenDatePicker(false)}
                  value={value ? dayjs(value) : null}
                  onChange={(date) => onChange(date ? dayjs(date).set('hour', 20).set('minute', 0).set('second', 0).format('YYYY-MM-DDTHH:mm:ss') : "")}
                  minDate={disablePast ? dayjs() : undefined}
                  slots={{
                    textField: StyledInput,
                  }}
                  slotProps={{
                    textField: {
                      onClick: () => setOpenDatePicker(true),
                      inputRef,
                    },
                    popper: {
                      anchorEl: inputRef.current,
                    },
                  }}
                />
              </LocalizationProvider>
            </Box>
          );
        }}
      />
    </FormControl>
  );
};

export default FormDatePicker;
