import axios from "axios";
import { useAuthStore } from "@/store/authStore.ts";

const baseURL = import.meta.env.VITE_API_HOST;

const authApi = axios.create({
  baseURL,
});

authApi.interceptors.request.use((config) => {
  const token = useAuthStore.getState().token;
  config.headers.Authorization = `Bearer ${token}`;

  // REMOVE NEXT LINE, ONLY FOR DEV MODE
  config.headers["ngrok-skip-browser-warning"] = "69420";

  // config.headers["Content-Type"] = "application/json";
  return config;
});

authApi.interceptors.response.use(
  (response) => {
    // If the response is successful, just return the response
    return response;
  },
  (error) => {
    // If the response has an error
    if (error.response && error.response.status === 401) {
      const reset = useAuthStore.getState().reset;
      reset();
    }

    return Promise.reject(error);
  },
);

export default authApi;

